export async function validateApoId(apoIdWithChecksum) {
    if(apoIdWithChecksum.length != 6) {
        return false;
    }
    const apoId = apoIdWithChecksum.substr(0, 4);
    const checksum = parseInt(apoIdWithChecksum.substr(4, 2), 10);
    if(calcChecksum(apoId) !== checksum) {
        return false;
    }
    try {
        await fetchData(apoId);
    } catch(e) {
        return false;
    }
    return apoId;
}

export function calcChecksum(apoId) {
    return apoId.split("").reduce((acc, i) => parseInt(i) + acc, 0)
}

async function fetchData(apoId) {
    const endpoint = `/data/${apoId}/data.json`
    const fetchedData = await fetch(endpoint);
    if(fetchedData.ok){
        return await fetchedData.json();
    }
    throw new Error(fetchedData.status.toString());
}

let data = {}
export async function loadData(apoId) {
    data = await fetchData(apoId);
}

export function getData(selector) {
    return data[selector];
}

export async function getNews() {
    try {
        const data = getData('news');
        const news = await (await fetch(data.endpoint)).json();
        return news;
    } catch (e) {
        return {feed: []};
    }
}

export function hasNews() {
    return getData('news') !== undefined;
}

export async function getOffers() {
    try {
        const data = getData('offers');
        const offers = await (await fetch(data.endpoint)).json();
        return offers;
    } catch (e) {
        return {feed: []};
    }
}

export function hasOffers() {
    return getData('offers') !== undefined;
}

export function getMainColor() {
    return getData('theme').color;
}

export function getLogo() {
    return getData('theme').logo;
}
