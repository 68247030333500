<template>
  <div>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
      <v-container>
        <v-row>
          <v-col>
            <v-img src="logo.png" contain max-height="50px" class="pb-5"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <v-text-field
                type="number"
                placeholder="Meine Apotheke"
                prepend-icon="mdi-numeric"
                v-model="apoId"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Geben Sie bitte den 6-stelligen Code Ihrer Apotheke ein.
            Diesen finden sie auch auf der Website Ihrer Apotheke.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
                color="success"
                class="mr-4"
                @click="submit"
            >
              Weiter
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-container>
      <v-row>
        <v-col>
          APOmobile - der direkte Draht zu Ihrer Stamm-Apotheke. Über diese App können Sie bequem Medikamente mit Rezept
          / Verpackung vorbestellen. Sie finden attraktive Angebote und Neuigkeiten aus Ihrer Apotheke und alle
          Notrufnummern auf einen Blick.
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="invalidMessage" centered light outlined color="red">
      Bitte geben Sie einen gültigen 6 stelligen Code Ihrer Apotheke ein
      <v-btn
          text
          @click="invalidMessage = false"
      >
        Schließen
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import {getApoId, setApoId} from "@/lib/user-data";
import {calcChecksum, validateApoId} from "@/lib/apo-data";
import {isInApp} from "@/lib/app-api";

export default {
  data: () => {
    const apoId = getApoId();
    return {
      valid: true,
      apoId: apoId ? apoId + `${calcChecksum(apoId)}`.padStart(2, 0) : '',
      invalidMessage: false
    };
  },
  methods: {
    async submit() {
      const apoId = await validateApoId(this.apoId ?? "")
      if (!apoId) {
        this.valid = false;
        this.invalidMessage = true;
        return;
      }
      this.valid = true;
      this.invalidMessage = false;
      setApoId(apoId);
      window.location = '/?apoId=' + apoId + (isInApp() ? '&app=true' : '');
    }
  }
}
</script>
