export function setApoIdFromUrl() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const apoId = urlParams.get('apoId');
    if (apoId && apoId.length === 4 && apoId !== "null") {
        setApoId(apoId);
    }
}

export function getApoId() {
    return localStorage.getItem('apo.id');
}
export function setApoId(apoId) {
    if(apoId) {
        localStorage.setItem('apo.id', apoId);
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('setApoId', apoId)
        }
    }
}
export function resetApoId() {
    localStorage.removeItem('apo.id');
}

function sanitizeAppData(data) {
    if (data === '{null}' || data === 'null' ||  data === null) {
        return '';
    }
    return data;
}

export async function getUserData() {
    if(window.flutter_inappwebview) {
        return {
            name: sanitizeAppData(await window.flutter_inappwebview.callHandler('getUserName')),
            phone: sanitizeAppData(await window.flutter_inappwebview.callHandler('getUserPhone')),
            email: sanitizeAppData(await window.flutter_inappwebview.callHandler('getUserEmail')),
            storecard: sanitizeAppData(await window.flutter_inappwebview.callHandler('getUserStorecard')),
        }
    }
    return {
        name: localStorage.getItem('user.name'),
        phone: localStorage.getItem('user.phone'),
        email: localStorage.getItem('user.email'),
        storecard: localStorage.getItem('user.storecard')
    }
}

export function setUserName(name) {
    localStorage.setItem('user.name', name);
    if(window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('setUserName', name)
    }
}

export function setUserPhone(phone) {
    localStorage.setItem('user.phone', phone);
    if(window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('setUserPhone', phone)
    }
}

export function setUserEmail(email) {
    localStorage.setItem('user.email', email);
    if(window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('setUserEmail', email)
    }
}

export function setUserStorecard(storecard) {
    localStorage.setItem('user.storecard', storecard);
    if(window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('setUserStorecard', storecard)
    }
}

export function setUserData(user) {
    setUserName(user.name);
    setUserPhone(user.phone);
    setUserEmail(user.email);
    setUserStorecard(user.storecard);
}
export function resetUserData() {
    localStorage.removeItem('user.name');
    localStorage.removeItem('user.phone');
    localStorage.removeItem('user.email');
    localStorage.removeItem('user.storecard')
}

function getNewsRead() {
    let read = [];
    try {
        const stored = localStorage.getItem('user.news.read');
        if (stored !== null) {
            read = JSON.parse(stored);
        }
    } catch (e) {
        console.error('could not parse read news');
    }
    return read;
}

export function isNewsRead(id) {
    const read = getNewsRead();
    return read.includes(id);
}

export function addNewsRead(id) {
    const read = getNewsRead();
    if(!read.includes(id)) {
        read.push(id)
        window.localStorage.setItem('user.news.read', JSON.stringify(read));
    }
}

export function printAllUserData() {
    function allStorage() {

        var values = {},
            keys = Object.keys(localStorage),
            i = keys.length;

        while ( i-- ) {
            values[keys[i]] = localStorage.getItem(keys[i]);
        }

        return values;
    }
    alert(JSON.stringify(allStorage(), null, 2));
}
