<template>
  <div :style="{'--main-color':mainColor}">
  <v-app>
    <v-container class="apo-content">
      <router-view/>
    </v-container>
    <v-footer app padless v-if="apoId">
      <BottomNavigation></BottomNavigation>
    </v-footer>
  </v-app>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

.apo-content {
  margin-bottom: 60px;
}

</style>
<script>
import BottomNavigation from './components/BottomNavigation'
import {getMainColor} from "@/lib/apo-data";
import {getApoId} from "@/lib/user-data";

export default {
  components: {BottomNavigation},
  data() {
    const apoId = getApoId();
    return {
      apoId,
      mainColor: apoId ? getMainColor() : 'ff0'
    }
  }
}
</script>
